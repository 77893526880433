import React from "react";

export default function GoogleImage2({ image }) {
  const extractImageId = (addr) => {
    let url = addr.split("/")[5];
    return url;
  };
  return (
    <img
      src={
        image
          ? `https://drive.google.com/thumbnail?id=${extractImageId(image)}`
          : "/img/placeholder.png"
      }
      alt="img"
      className="img-fluid rounded "
    />
  );
}
