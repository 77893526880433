import React from 'react';
import { Routes, Route } from 'react-router-dom';
import CoinStaking from '../CoinStaking/CoinStaking';
import Admin from '../Admin/Admin';
import CoinStakingDetails from '../CoinStaking/CoinStakingDetails';

const MyRoutes = () => {
  return (
    <div>
      <Routes>
        <Route exact path='/admin' element={<Admin />} />

        <Route path='/'>
          <Route index element={<CoinStaking />} />
          <Route path=':id' element={<CoinStakingDetails />} />
        </Route>
        <Route exact path='/admin/:address' element={<Admin />} /> 
        {/* 
        <Route exact path='/admin' element={<Admin />} />
        */}
      </Routes>
    </div>
  );
};
export default MyRoutes;
