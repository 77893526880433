import React, { useEffect, useState } from "react";
import {
  coinContractInst,
  stakingContractInst,
  tokenContractInst,
} from "../../contracts/contractInstance";
import { Modal, Button } from "react-bootstrap";

import { useConnectWallet } from "@web3-onboard/react";
import {
  handleClaim,
  handleEarned,
  handleStake,
  handleTotalSupply,
  handleUnStake,
} from "../function";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import GoogleImage from "./GoogleImage";
import { validUser } from "../common/utils";
import { ToastContainer, toast } from "react-toastify";
import GoogleImage2 from "./GoogleImage2";

export default function CoinStakingDetails() {
  const params = useParams();
  console.log("🚀 ~ CoinStakingDetails ~ params:", params);
  const collection = useSelector((state) => state.coinContract.collection);
  console.log("🚀 ~ CoinStakingDetails ~ collection:", collection);

  // console.log("params:", params?.id)
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [stakeAmount, setStakeAMount] = useState(0);
  const [unStakeAmount, setUnStakeAMount] = useState(0);
  const [isLoading, setIsLoading] = useState("");
  const [currentCollection, setCurrentCollection] = useState([]);
  const [admin, setAdmin] = useState(false);
  const [stakeTokenSym, setStakeTokenSym] = useState("");
  const [rewardTokenSym, setrewardTokenSym] = useState("");
  const [rewardAmount, setRewardAmount] = useState("");
  const [rewardDuration, setRewardDuration] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [balanceOf, setBalanceOf] = useState("");
  const [earnedAmount, setEarnedAmount] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isWalletConnect, setIsWalletConnect] = useState(true);
  const [rwrdtokendec, setRwrdTokenDec] = useState(0);
  const [staketokendec, setStakeTokenDec] = useState(0);
  const handleClose = () => setShowModal(false);

  const handleShow = () => setShowModal(true);
  const handleShow2 = () => setShowModal2(true);

  // 2nd modal
  const handleClose2 = () => setShowModal2(false);
  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    // Handle submission logic here, e.g., send input value to backend
    console.log("Input Value:", inputValue);
    // Close the modal
    handleClose();
  };

  const handleStakeFunction = async () => {
    try {
      console.log("wallet:", wallet);
      if (stakeAmount === 0 || stakeAmount === "0") {
        return toast.error("Value should be greater than 0");
      }
      setIsLoading("stake");
      let _stake = await handleStake(wallet, stakeAmount, currentCollection);
      console.log("_stake:", _stake);
      if (_stake === true) {
        setIsLoading("");
        toast.success("Stake done successfully");
        getRewardAmount(currentCollection);
        handleClose();
        setStakeAMount("");
      } else {
        setIsLoading("");
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log("error:", error);
      setIsLoading("");
    }
  };

  const handleUnStakeFunction = async () => {
    try {
      if (unStakeAmount === 0 || unStakeAmount === "0") {
        return toast.error("Value should be greater than 0");
      }
      setIsLoading("unstake");
      console.log("wallet:", wallet);
      let _stake = await handleUnStake(
        wallet,
        unStakeAmount,
        currentCollection
      );
      console.log("_stake:", _stake);
      if (_stake === true) {
        setIsLoading("");
        toast.success("Unstake Done");
        getRewardAmount(currentCollection);
        handleClose2();
        setUnStakeAMount("");
      } else {
        setIsLoading("");
        toast.error("Something went wrong");
      }
    } catch (error) {
      console.log("error:", error);
      setIsLoading("");
    }
  };
  const handleClaimFunction = async () => {
    try {
      setIsLoading("claim");
      let _claim = await handleClaim(wallet, currentCollection);
      if (_claim === true) {
        setIsLoading("");
        toast.success("Claim Done");
        getRewardAmount(currentCollection);
      } else {
        setIsLoading("");
        toast.error("Something went wrong");
        console.log("_claim:", _claim);
      }
    } catch (error) {
      setIsLoading("");
      toast.error("Something went wrong");
    }
  };

  const getTotalSupply = async () => {
    console.log("get total supply function called");
    let totalSupply = await handleTotalSupply(params?.id, wallet);
    console.log("aaaa", totalSupply);
  };
  useEffect(() => {
    getTotalSupply();
    // handleEarnedFunctions();
    return () => {};
  }, []);
  useEffect(() => {
    if (collection?.length > 0) {
      // console.log('🚀 ~ useEffect ~ collection', collection);
      // console.log(' collection -----');

      let filterData = collection.filter((e) => {
        // console.log("1234567890", e?.contract_address, "---", params.id);
        return e.contract_address?.toLowerCase() === params?.id?.toLowerCase();
      });
      // console.log("🚀 ~ useEffect ~ filterData[0]", filterData[0]);
      setCurrentCollection(filterData[0]);
      handleAdmin(filterData[0]);
      getName(filterData[0]);
      getRewardAmount(filterData[0]);
    } else {
      console.log("no collection");
    }
    return () => {};
  }, [collection, wallet?.accounts[0].address]);

  const handleAdmin = (currentCollection) => {
    try {
      console.log("collextion", currentCollection);
      let _user = validUser(
        wallet?.accounts[0].address,
        currentCollection?.creator_address
      );
      // console.log('🚀 ~collextion handleAdmin ~ _user', _user)
      setAdmin(_user);
    } catch (error) {
      console.log("🚀 ~ handleAdmin ~ error:", error);
    }
  };

  const getName = async (coll) => {
    try {
      console.log("handleToken", coll);
      let rewardTokenContr = await tokenContractInst(
        wallet?.provider,
        coll.reward_token_address
      );
      let stakeTokenContr = await tokenContractInst(
        wallet?.provider,
        coll.staking_token_address
      );
      console.log("staking", rewardTokenContr);
      let rewardTokenSymName = await rewardTokenContr.symbol();
      let _rwrdTokenDecimal = await rewardTokenContr.decimals();
      console.log("🚀 ~ getName ~ rewardTokenSymName:", rewardTokenSymName);
      let stakeTokenSymName = await stakeTokenContr.symbol();
      let _stakeTokenDecimal = await stakeTokenContr.decimals();
      console.log("🚀 ~ getName ~ _stakeTokenDecimal:", _stakeTokenDecimal);

      console.log("🚀 ~ getName ~ stakeTokenSymName:", stakeTokenSymName);
      setrewardTokenSym(rewardTokenSymName);
      setStakeTokenSym(stakeTokenSymName);
      setRwrdTokenDec(_rwrdTokenDecimal);
      setStakeTokenDec(_stakeTokenDecimal);
    } catch (error) {
      console.log("🚀 ~ getName ~ error:", error);
    }
    // return name;
  };
  const getRewardAmount = async (collection) => {
    try {
      let stakingContr = await stakingContractInst(
        wallet?.provider,
        collection.contract_address
      );

      let amount = await stakingContr.rewardAmount();
      let duration = await stakingContr.rewardsDuration();
      let totalSupply = await stakingContr.totalSupply();
      let _balanceOf = await stakingContr.balanceOf(
        wallet?.accounts[0].address
      );
      let _earned = await stakingContr.earned(wallet?.accounts[0].address);
      console.log("🚀 ~ getRewardAmount ~ _earned:", _earned?.toString());

      setRewardAmount(amount.toString());
      setRewardDuration(duration.toString());
      setTotalSupply(totalSupply.toString());
      setBalanceOf(_balanceOf.toString());
      setEarnedAmount(_earned.toString());
    } catch (error) {
      console.log("🚀 ~ getRewardAmount ~ error:", error);
    }
  };
  return (
    <>
      <ToastContainer />
      <section>
        <div className="d-flex justify-content-center">
          {admin ? (
            <Link
              className="text-center btn mb-2"
              to={`/admin/${params?.id}`}
              state={currentCollection}
            >
              Update contract
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="container">
          <div class="row border py-3 px-2 rounded">
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <GoogleImage2  image={currentCollection?.picture} />
            </div>
            {/* <div className="col-md-1"></div> */}

            {wallet?.accounts[0].address ? (
              <div className="col-12 col-md-7">
                <p className="mainText">
                  Name :{" "}
                  <span className="subText"> {currentCollection.name} </span>
                </p>
                <p className="mainText">
                  Description :{" "}
                  <span className="subText">
                    {" "}
                    {currentCollection.description}
                  </span>
                </p>
                <p className="mainText">
                  Pool :{" "}
                  <span className="subText">
                    {" "}
                    {stakeTokenSym}/{rewardTokenSym}
                  </span>
                </p>
                <p className="mainText">
                  Reward Amount :{" "}
                  <span className="subText">
                    {" "}
                    {(rewardAmount / 10 ** rwrdtokendec).toFixed(4)}{" "}
                    {rewardTokenSym}
                  </span>
                </p>
                <p className="mainText">
                  Reward Duration :{" "}
                  <span className="subText"> {rewardDuration}</span>
                </p>
                <p className="mainText">
                  Total Stake:{" "}
                  <span className="subText">
                    {" "}
                    {totalSupply / 10 ** staketokendec} {stakeTokenSym}
                  </span>
                </p>
                <p className="mainText">
                  Stake Amount:{" "}
                  <span className="subText">
                    {" "}
                    {balanceOf / 10 ** staketokendec} {stakeTokenSym}
                  </span>
                </p>
                <p className="mainText">
                  Earned:{" "}
                  <span className="subText">
                    {(earnedAmount / 10 ** rwrdtokendec).toFixed(4)}{" "}
                    {rewardTokenSym}
                  </span>
                </p>
              </div>
            ) : (
              <div className="col-12 d-flex justify-content-center">
                <button className="btn" onClick={() => connect()}>
                  Connect Wallet
                </button>
              </div>
            )}
          </div>

          <div className="row">
            {wallet?.accounts[0].address ? (
              <div className="col-12 col-md-12  p-2 rounded">
                <div
                  className="d-grid d-md-flex justify-content-normal justify-content-md-center "
                  style={{
                    display: "grid",
                  }}
                >
                  <button
                    className="btn mx-2 mt-2 mt-md-0"
                    onClick={handleShow}
                  >
                    stake
                  </button>
                  <button
                    className="btn mx-2 mt-2 mt-md-0"
                    onClick={handleShow2}
                  >
                    Unstake
                  </button>
                  {isLoading === "claim" ? (
                    <button className="btn px-5 py-1">
                      <div
                        class="spinner-border text-white "
                        role="status"
                      ></div>
                    </button>
                  ) : (
                    <button
                      className="btn mx-2 mt-2 mt-md-0"
                      onClick={handleClaimFunction}
                    >
                      claim
                    </button>
                  )}
                  {/* <button className='btn'>Earned</button> */}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </section>
      {/* stake */}
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="text-dark">Enter Stake value</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter something..."
            value={stakeAmount}
            onChange={(e) => setStakeAMount(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          {isLoading === "stake" ? (
            <Button variant="secondary px-5 py-2">
              <div class="spinner-border text-white " role="status"></div>
            </Button>
          ) : (
            <Button variant="primary" onClick={handleStakeFunction}>
              Stake
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {/* unstake */}
      <Modal show={showModal2} onHide={handleClose2} centered>
        <Modal.Header>
          <Modal.Title className="text-dark">Enter Unstake value</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            className="form-control"
            placeholder="Enter something..."
            value={unStakeAmount}
            onChange={(e) => setUnStakeAMount(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose2}>
            Cancel
          </Button>

          {isLoading === "unstake" ? (
            <Button variant="secondary px-5 py-2">
              <div class="spinner-border text-white " role="status"></div>
            </Button>
          ) : (
            <Button variant="primary" onClick={handleUnStakeFunction}>
              UnStake
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
