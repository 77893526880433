import React from "react";

export default function GoogleImage({ image }) {
  const extractImageId = (addr) => {
    let url = addr.split("/")[5];
    return url;
  };
  return (
    <div className=" d-flex justify-content-center myNftDetailsImg ">

    <img
    
      src={
        image
          ? `https://drive.google.com/thumbnail?id=${extractImageId(image)}`
          : "/img/placeholder.png"
      }
      alt="img"
    />
    </div>
  );
}
