import { ethers } from 'ethers';
import { coinContractAbi, coinContractAddr } from './coinContract'
import { nftTokenAbi } from './nftContract';
import { tokenAbi } from './token';


export const stakingContractInst = async (provider,addr) => {
  const etherProvider = new ethers.providers.Web3Provider(provider);
  const etherSigner = etherProvider?.getSigner();
  const _stakingContractInst = new ethers.Contract(
    addr?.toLowerCase(),
    coinContractAbi,
    etherSigner
  );
  return _stakingContractInst;
};

export const tokenContractInst = async (provider, address) => {
  try {


    const etherProvider = new ethers.providers.Web3Provider(provider);
    const etherSigner = etherProvider?.getSigner();
    const _tokenContractInst = new ethers.Contract(
      address,
      tokenAbi,
      etherSigner
    );
    return _tokenContractInst;
  } catch (error) {
    console.log("error:", error)

  }
}

export const nftContractInst = async (provider, addr) => {
  try {


    const etherProvider = new ethers.providers.Web3Provider(provider);
    const etherSigner = etherProvider?.getSigner();
    const _nftContractInst = new ethers.Contract(
      addr?.toLowerCase(),
      nftTokenAbi,
      etherSigner
    );
    return _nftContractInst;
  } catch (error) {
    console.log("error:", error)

  }
};


export const coinContractInst = async (provider) => {
  try {


    const etherProvider = new ethers.providers.Web3Provider(provider);
    const etherSigner = etherProvider?.getSigner();
    const _coinContractInst = new ethers.Contract(
      coinContractAddr,
      coinContractAbi,
      etherSigner
    );
    return _coinContractInst;

  } catch (error) {
    console.log("error:", error)

  }
};

