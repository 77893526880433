import { combineReducers, configureStore } from '@reduxjs/toolkit'
import counterReducer from './features/counterSlice'
import coinContract from './features/coinContract'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  counter:counterReducer,
  coinContract: coinContract
  
})
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer
})
export const persistor = persistStore(store)