import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ChainId, DAppProvider, Goerli, Mainnet } from "@usedapp/core";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import "bootstrap/dist/js/bootstrap.js";
import "react-toastify/dist/ReactToastify.css";

// import 'bootstrap/dist/css/bootstrap.min.css';
// console.log = function () {};

const config = {
  pollingInterval: 5000,
  autoConnect: true,
  readOnlyChainId:
    process.env.REACT_APP_ENVIRONMENT == "DEV"
      ? ChainId.CronosTestnet
      : ChainId.Cronos,
  readOnlyUrls: {
    [process.env.REACT_APP_ENVIRONMENT == "DEV"
      ? ChainId.CronosTestnet
      : ChainId.Cronos]:
      process.env.REACT_APP_ENVIRONMENT == "DEV"
        ? process.env.REACT_APP_CRONOS_TESTNET_RPC
        : process.env.REACT_APP_CRONOS_RPC,
  },
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <DAppProvider config={config}>
            <App />
          </DAppProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
