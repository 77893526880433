import React, { useEffect, useState } from "react";
import "./coinstaking.css";
import Finished from "./categories/Finished";
// import Paused from './categories/Paused';
import Live from "./categories/Live";
import axios from "axios";
import { useDispatch } from "react-redux";
import { saveCollection } from "../../redux/features/coinContract";
export default function CoinStaking() {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [pools, setAllpools] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const activeClass = {
    background: "#0b9e15",
  };
  useEffect(() => {
    getAllPools();

    return () => {};
  }, []);

  const getAllPools = async () => {
    try {
      setIsloading(true)
      let allPools = await axios.get(
        `${process.env.REACT_APP_API_URL}coinContract`
      );
      console.log("allPools:", allPools);
      setAllpools(allPools?.data);
      dispatch(saveCollection(allPools?.data));
      setIsloading(false)

    } catch (error) {
      setIsloading(false)

      console.log("🚀 ~ getAllPools ~ error:", error);
    }
  };

  return (
    <section className="staking-area">
      <div className="container-fluid">
        <div className="row stack_row_box justify-content-center">
          {/* <div className="col-md-7 border text-white single-staking-div pb-2 ">
            <h5 className="pt-3 m-0">Single Staking</h5>
            <p className="staking-text pt-3 pb-1 mt-0">
              Stake your tokens to receive stable, predictable APR without
              impermanent loss.
            </p>
          </div> */}
        </div>
        <div className="row pt-5">
          <div className="col-12 d-flex justify-content-center fplSection">
            {/* <div
              onClick={() => setActiveTab(1)}
              style={activeTab === 1 ? activeClass : {}}
            >
              Pause
            </div> */}
            <div
              onClick={() => setActiveTab(2)}
              style={activeTab === 2 ? activeClass : {}}
            >
              Live
            </div>
            <div
              onClick={() => setActiveTab(0)}
              style={activeTab === 0 ? activeClass : {}}
            >
              Finished
            </div>
          </div>
        </div>
        <div className="row stack_row_box  justify-content-center pt-5">
          {/* <div className="col-md-7 border text-white single-staking-div pb-2 ">
            <div className="row m-0 p-0">
              <div className="col m-0 p-0">
                <h5 className="pt-3 m-0">My Deposits Only </h5>
              </div>
              <div className="col d-flex justify-content-end on-off-btn pt-2">
                <div>
                  <button>
                    <span>OFF</span>
                  </button>
                  <button>
                    <span>On</span>
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="col m-0 p-0">
                <p className="staking-text pt-3 mt-0 pb-1">Filter Tokens</p>
              </div>
              <div className="col d-flex justify-content-end search-box-div">
                <input type="text" name="" id="" className="search-box" />
              </div>
            </div>
          </div> */}
        </div>
        <div className="row stack_row_box justify-content-around pt-3 ">
          {activeTab === 0 ? <Finished pools={pools} isLoading={isLoading} /> : ""}
          {/* {activeTab === 1 ? <Paused pools={pools} /> : ''} */}
          {activeTab === 2 ? <Live pools={pools} isLoading={isLoading} /> : ""}
        </div>
      </div>
    </section>
  );
}
