import { ethers } from "ethers";
import {
  coinContractInst,
  nftContractInst,
  stakingContractInst,
  tokenContractInst,
} from "../contracts/contractInstance";
import bigInt from "big-integer";

export const handleTotalSupply = async (addr, wallet) => {
  try {
    //   // setIsLoading(true);
    const _coinContract = await coinContractInst(wallet?.provider, addr);
    console.log("Transaction", _coinContract);

    const _total = await _coinContract.rewardsToken();
    console.log("🚀 ~ handleTotalSupply ~ _cl̥aim:", _total.toString());
    return _total.toString();
    // console.log("ans",totalSupply.toString())
    // if (_wait) {
    //     // toast.success('Claim Success');
    //     // handleRewardsToken();
    //     // setIsLoading(false);
    // }
  } catch (error) {
    //   // setIsLoading(false);
    //   toast.error('Something went wrong!');

    console.log("🚀 ~ handle total supply ~ error", error);
  }
};

// const handleBalanceOf = async () => {

//     try {
//         //   // setIsLoading(true);
//         const _coinContract = await coinContractInst(
//             wallet?.provider,
//         );

//         const _claim = await _coinContract.balanceOf(address,account);
//         let wait = await _claim.wait();
//         if (wait) {
//             // toast.success('Claim Success');
//             handleRewardsToken();
//             // setIsLoading(false);
//         }
//     } catch (error) {
//         //   // setIsLoading(false);
//         //   toast.error('Something went wrong!');

//         console.log('🚀 ~ handleClaim ~ error', error);
//     }
// };

// const handleClaimChargeFee= async () => {

//     try {
//         //   // setIsLoading(true);
//         const _coinContract = await coinContractInst(
//             wallet?.provider,
//         );

//         const _claim = await _coinContract.claimChargeFee();
//         let wait = await _claim.wait();
//         if (wait) {
//             // toast.success('Claim Success');
//             handleRewardsToken();
//             // setIsLoading(false);
//         }
//     } catch (error) {
//         //   // setIsLoading(false);
//         //   toast.error('Something went wrong!');

//         console.log('🚀 ~ handleClaim ~ error', error);
//     }
// };

export const handleStake = async (wallet, stakeAmount, coll) => {
  try {
    // add approval function
    // const app

    const stakingTokenContr = await tokenContractInst(
      wallet?.provider,
      coll.staking_token_address
    );

    console.log("token", stakingTokenContr);
    let decimal = await stakingTokenContr.decimals();
    console.log("decimal", decimal);
    let addDecimal = bigInt(stakeAmount * 10 ** decimal).toString();
    console.log("add decimal", addDecimal);
    let approve = await stakingTokenContr.approve(
      coll.contract_address,
      addDecimal
    );
    let waitFortx = await approve.wait();
    console.log("approve", approve);
    const _coinContract = await stakingContractInst(
      wallet?.provider,
      coll.contract_address
    );

    console.log("Coin Contract", _coinContract);
    const isFeeEnable = await _coinContract.isStakingFeeEnable();
    console.log("Coin Contract fees", isFeeEnable);
    let amount = bigInt(stakeAmount * 10 ** decimal).toString();
    if (isFeeEnable) {
      let stakeFee = await _coinContract.stakeFee();
      console.log("🚀 ~ handleClaim ~ stakeFee", stakeFee.toString());
      console.log("stake amount", amount);
      const _stake = await _coinContract.stake(amount, {
        value: stakeFee.toString(),
      });
      let wait = await _stake.wait();
      if (wait) {
        return true;
      }
    } else {
      const _stake = await _coinContract.stake(amount);
      let wait = await _stake.wait();
      if (wait) {
        return true;
      }
    }
  } catch (error) {
    console.log("🚀 ~ handleClaim ~ error", error);
    return error;
  }
};

export const handleUnStake = async (wallet, stakeAmount, coll) => {
  try {
    // add approval function
    // const app

    const stakingTokenContr = await tokenContractInst(
      wallet?.provider,
      coll.staking_token_address
    );

    console.log("token", stakingTokenContr);
    let decimal = await stakingTokenContr.decimals();
    console.log("decimal", decimal);
    let addDecimal = bigInt(stakeAmount * 10 ** decimal).toString();
    console.log("add decimal", addDecimal);

    const _coinContract = await stakingContractInst(
      wallet?.provider,
      coll.contract_address
    );

    console.log("Coin Contract", _coinContract);
    const isFeeEnable = await _coinContract.isStakingFeeEnable();
    console.log("Coin Contract fees", isFeeEnable);
    let amount = bigInt(stakeAmount * 10 ** decimal).toString();
    if (isFeeEnable) {
      let stakeFee = await _coinContract.stakeFee();
      console.log("🚀 ~ handleClaim ~ stakeFee", stakeFee.toString());
      console.log("stake amount", amount);
      const _stake = await _coinContract.unStake(amount, {
        value: stakeFee.toString(),
      });
      let wait = await _stake.wait();
      if (wait) {
        return true;
      }
    } else {
      const _stake = await _coinContract.unStake(amount);
      let wait = await _stake.wait();
      if (wait) {
        return true;
      }
    }
  } catch (error) {
    console.log("🚀 ~ handleClaim ~ error", error);
    return error;
  }
};

export const handleClaim = async (wallet, coll) => {
  try {
    // add approval function
    // const app

    const _coinContract = await stakingContractInst(
      wallet?.provider,
      coll.contract_address
    );

    const isFeeEnable = await _coinContract.isStakingFeeEnable();
    if (isFeeEnable) {
      let stakeFee = await _coinContract.stakeFee();
      const _stake = await _coinContract.claim({
        value: stakeFee.toString(),
      });
      let wait = await _stake.wait();
      if (wait) {
        return true;
      }
    } else {
      const _stake = await _coinContract.claim();
      let wait = await _stake.wait();
      if (wait) {
        return true;
      }
    }
  } catch (error) {
    console.log("🚀 ~ handleClaim ~ error", error);
    return error;
  }
};

export const handleEarned = async (wallet) => {
  try {
    //   // setIsLoading(true);
    const _coinContract = await coinContractInst(wallet?.provider);

    const _claim = await _coinContract.earned(wallet.accounts[0]);
    let wait = await _claim.wait();
    if (wait) {
      return wait;
    }
  } catch (error) {
    //   // setIsLoading(false);

    console.log("🚀 ~ handleClaim ~ error", error);
    return error;
  }
};

const handleDecimal = () => {
  try {
  } catch (error) {
    console.log("error", error);
  }
};
// const handleSetRewardsDuration= async () => {

//     try {
//         //   // setIsLoading(true);
//         const _coinContract = await coinContractInst(
//             wallet?.provider,
//         );

//         const _setRewardsDuration= await _coinContract.setRewardsDuration(rewardDuration);
//         let wait = await _setRewardsDuration.wait();
//         if (wait) {
//             // toast.success('Claim Success');
//             // setIsLoading(false);
//         }
//     } catch (error) {
//         //   // setIsLoading(false);
//         //   toast.error('Something went wrong!');

//         console.log('🚀 ~ handleClaim ~ error', error);
//     }
// };

// const handleSetRewardAmount= async () => {

//     try {
//         //   // setIsLoading(true);
//         const _coinContract = await coinContractInst(
//             wallet?.provider,
//         );

//         const _setRewardAmount= await _coinContract.setRewardAmount(rewardAmount);
//         let wait = await _setRewardAmount.wait();
//         if (wait) {
//             // toast.success('Claim Success');
//             // setIsLoading(false);
//         }
//     } catch (error) {
//         //   // setIsLoading(false);
//         //   toast.error('Something went wrong!');

//         console.log('🚀 ~ setRewardAmount ~ error', error);
//     }
// };
// const handleRecoverERC20= async () => {

//     try {
//         //   // setIsLoading(true);
//         const _coinContract = await coinContractInst(
//             wallet?.provider,
//         );

//         const _recoverERC20= await _coinContract.recoverERC20(address,amount);
//         let wait = await _recoverERC20.wait();
//         if (wait) {
//             // toast.success('Claim Success');
//             // setIsLoading(false);
//         }
//     } catch (error) {
//         //   // setIsLoading(false);
//         //   toast.error('Something went wrong!');

//         console.log('🚀 ~ recoverERC20 ~ error', error);
//     }
// };

