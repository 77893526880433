import React from "react";
import "./categories.css";
import { Link } from "react-router-dom";
import GoogleImage from "../GoogleImage";
import {
  stakingContractInst,
  tokenContractInst,
} from "../../../contracts/contractInstance";
import { useConnectWallet } from "@web3-onboard/react";
export default function Finished({ pools, isLoading }) {
  console.log("🚀 ~ Finished ~ pools:", pools);
  const [{ wallet }] = useConnectWallet();

  return (
    <>
      {isLoading ? (
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden"></span>
        </div>
      ) : (
        <>
          {" "}
          {pools ?? pools.length > 0
            ? pools.map((item, index) => {
                console.log("ite", item);
                return (
                  <Link
                    to={`/${item?.contract_address}`}
                    className=" my-2    tokenBox blueColor "
                    key={index}
                  >
                    <div>
                      <div className="row d-flex justify-content-center">
                        <GoogleImage image={item?.picture} />
                      </div>
                      <h4 className="text-center my-1">{item?.name}</h4>
                      <div className="row p-0 m-0 pt-2 border-top myFontSize">
                        <div className="col  text-center">
                          <small className="text-center ">Description</small>
                          <p className="fs-6 text-center">
                            {item?.description}
                          </p>
                        </div>
                      </div>
                      {/* <div className="row p-0 m-0 pt-2 border-top myFontSize">
                    <div className="col-6 px-1 ">Total Supply</div>
                    <div className="col-6 px-1 d-flex justify-content-end">
                      $42,205
                    </div>
                  </div>
                  <div className="row p-0 m-0 pt-1 myFontSize ">
                    <div className="col-7 px-1 ">Reward Duration </div>
                    <div className="col-5 px-1 d-flex justify-content-end">
                      1,537
                    </div>
                  </div> */}
                      <div className="row p-0 m-0 pt-1 myFontSize">
                        {/* <div className="col-7 px-1 ">Pool</div>
                    <div className="col-5 px-1 d-flex justify-content-end">
                      {getName(item?.staking_token_address)}
                    </div> */}
                      </div>
                      {/* <div className="row p-0 m-0 pt-1 ">
                  <div className="col-7 px-1">APR {'->'} APY</div>
                  <div className="col-5 px-1 d-flex justify-content-end">
                    8% {'->'} 8 %
                  </div>
                </div> */}
                    </div>
                  </Link>
                );
              })
            : "No List"}
        </>
      )}
    </>
  );
}
